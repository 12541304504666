import React from 'react';
import Launch from 'views/Launch';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const DesignCompanyPage = () => {
  return (
    <WithLayout
      component={Launch}
      layout={Main}
    />
  )
};

export default DesignCompanyPage;
